
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('../../../node_modules/algoliasearch/dist/algoliasearch.jquery.min.js');
require('../../../node_modules/autocomplete.js/dist/autocomplete.jquery.min.js');
require('../../../node_modules/enquire.js/dist/enquire.min.js');
require('./poshop_functions');

window.Vue = require('vue');
import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#wrapper'
});
